import React from 'react';
import './App.css';
import SurveyComponent from "./SurveyComponent";

function App() {
  return (
    <div className="App" style={{textAlign: "left"}}>
      <SurveyComponent />
    </div>
  );
}

export default App;
