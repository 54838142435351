import * as firebaseApp from "firebase/app";
import * as firebaseAuth from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase } from "firebase/database";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCraMIrsFRjbpMxoUcu8qSqf5OUbiNrP_Q",
    authDomain: "meingesangbuch.firebaseapp.com",
    projectId: "meingesangbuch",
    storageBucket: "meingesangbuch.appspot.com",
    messagingSenderId: "677348744781",
    appId: "1:677348744781:web:cc9bc162e5c66ae91f4604"
};


export const getApp = () => {
    if (firebaseApp.getApps().length) {
        return firebaseApp.getApp();
    }
    return firebaseApp.initializeApp(firebaseConfig);
};

export const getAuth = () => {
    return firebaseAuth.getAuth(getApp());
};

const _functions = getFunctions(getApp(), "europe-west1");

export const functions = {
    get sendSurveyResults() {
        return httpsCallable<{
            result: string;
        }, {
            status: string,
            error: string
        }
        >(_functions, "sendSurveyResults");
    }
};

export const database = getDatabase(getApp());
