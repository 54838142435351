const json = {
    "locale": "de",
    "title": {
        "de": "Digitales Gesangbuch - Umfrage"
    },
    "pages": [
        {
            "name": "Einführung",
            "elements": [
                {
                    "type": "html",
                    "name": "intro",
                    "html": {
                        "de": "Sehr geehrte Gemeindemitglieder und Kirchenverantwortliche,<br><br>\n\ndie Einführung des neuen evangelischen Gesangbuchs steht in den kommenden Jahren bevor und mit ihr die Chance, den Umgang mit unserem kirchlichen Liedgut zu modernisieren und zu vereinfachen. Stellen Sie sich eine auf Ihre Bedürfnisse maßgeschneiderte App vor, die Sie im Umgang mit christlichen Liedern auf vielfältige Weise unterstützt.<br><br>\n\nWir, die Kohelet 3 GmbH, befinden uns gerade in der Entwicklung einer solchen App und unser Ziel ist es, sie optimal an Ihre Bedürfnisse anzupassen. Ihre Teilnahme an unserer kurzen Umfrage ist dabei von großer Bedeutung – Sie ermöglicht es uns, ein tiefgreifendes Verständnis für die Herausforderungen und Bedürfnisse von Ihnen und Ihrer Gemeinde zu erlangen.<br><br>\n\nÜber diese Umfrage können Sie die Entwicklung dieser App aktiv mitgestalten! Ihre Stimme und Ihre Ideen werden einen wesentlichen Beitrag zur Zukunft des kirchlichen Liedguts leisten. Bitte nehmen Sie sich ein paar Minuten Zeit, um uns dabei zu unterstützen, eine technologisch fortschrittliche und gleichzeitig bedeutungsvolle Lösung zu kreieren.<br><br>\n\nHerzlichen Dank für Ihre Mithilfe!<br><br>\n\nMit freundlichen Grüßen<br>\nSimon Waloschek<br>(Geschäftsführer Kohelet 3 GmbH)\n\n<br><br><br>\n<b>Datenschutzhinweise</b><br>\nDie Auswertung dieser Umfrage erfolgt in anonymisierter Form und die von Ihnen bereitgestellten Antworten werden streng vertraulich behandelt.<br>\n\nAm Ende der Umfrage haben Sie die Möglichkeit, Ihre Kontaktdaten zu hinterlassen, falls Sie an weiteren Informationen zur Entwicklung des Projekts interessiert sind.<br><br>\n\nFalls Sie weitere Fragen haben, schreiben Sie uns gerne an <a href=\"mailto:info@cantico.me\">info@cantico.me</A>.\n<br><br><br>\n\n<b><a href=\"https://www.cantico.me/meta/impressum\">Impressum</a></b>"
                    }
                }
            ],
            "title": {
                "de": "Einführung"
            }
        },
        {
            "name": "Aktuelle Arbeitsweisen",
            "elements": [
                {
                    "type": "checkbox",
                    "name": "welche-abschnitte-oder-elemente-des-gesangbuchs-werden-in-ihrer-gemeinde-am-häufigsten-genutzt",
                    "title": {
                        "de": "Welche Abschnitte oder Elemente des Gesangbuchs werden in Ihrer Gemeinde am häufigsten genutzt?"
                    },
                    "isRequired": true,
                    "choices": [
                        "Lieder",
                        "Gebete",
                        "Liturgische Texte",
                        "Psalmen"
                    ],
                    "showOtherItem": true
                },
                {
                    "type": "checkbox",
                    "name": "wie-werden-lieder-während-des-gottesdienstes-in-ihrer-gemeinde-angekündigt-bzw-dargestellt",
                    "title": {
                        "de": "Wie werden Lieder während des Gottesdienstes in Ihrer Gemeinde angekündigt bzw. dargestellt?"
                    },
                    "isRequired": true,
                    "choices": [
                        "Anzeige der Liednummern",
                        "Ansage des Titels / der Liednummer",
                        "Beamer-Präsentation",
                        "Gedruckter Ablaufplan",
                        "Liedblätter",
                        "Vorsingen"
                    ],
                    "showOtherItem": true
                },
                {
                    "type": "radiogroup",
                    "name": "wie-oft-nutzen-sie-ergänzende-materialen-zum-gesangbuch-zb-liedkommentare-musikalische-arrangements-oder-alternative-textversionen",
                    "title": {
                        "de": "Wie oft nutzen Sie ergänzende Materialen zum Gesangbuch (z.B. Liedkommentare, musikalische Arrangements oder alternative Textversionen)?"
                    },
                    "isRequired": true,
                    "choices": [
                        "Immer",
                        "Oft",
                        "Manchmal",
                        "Selten",
                        "Nie"
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "wie-oft-singen-sie-lieder-die-nicht-im-gesangbuch-stehen-in-kirchlichen-veranstaltungen",
                    "title": {
                        "de": "Wie oft singen Sie Lieder, die nicht im Gesangbuch stehen, in kirchlichen Veranstaltungen?"
                    },
                    "isRequired": true,
                    "choices": [
                        "Immer",
                        "Oft",
                        "Manchmal",
                        "Selten",
                        "Nie"
                    ]
                },
                {
                    "type": "matrix",
                    "name": "wie-wichtig-wäre-es-für-sie-leichteren-zugang-zu-ergänzenden-materialien-zum-gesangbuch-zu-haben",
                    "title": {
                        "de": "Wie wichtig wäre es für Sie, leichteren Zugang zu ergänzenden Materialien zum Gesangbuch zu haben?"
                    },
                    "isRequired": true,
                    "columns": [
                        "Unwichtig",
                        "Eher unwichtig",
                        "Eher wichtig",
                        "Sehr wichtig"
                    ],
                    "rows": [
                        "Liedkommentare",
                        "Musikalische Arrangements",
                        "Alternative Textversionen"
                    ]
                },
                {
                    "type": "comment",
                    "name": "gibt-es-schwierigkeiten-oder-herausforderungen-im-umgang-mit-dem-gedruckten-gesangbuch-die-sie-gerne-verbessert-sehen-würden",
                    "title": "Gibt es Schwierigkeiten oder Herausforderungen im Umgang mit dem gedruckten Gesangbuch, die Sie gerne verbessert sehen würden?"
                },
                {
                    "type": "comment",
                    "name": "wenn-sie-an-die-zukünftige-nutzung-des-gesangbuchs-denken-welche-aspekte-sind-ihnen-besonders-wichtig",
                    "title": "Wenn Sie an die zukünftige Nutzung des Gesangbuchs denken, welche Aspekte sind Ihnen besonders wichtig?"
                }
            ],
            "title": {
                "de": "Aktuelle Arbeitsweisen"
            },
            "description": {
                "de": "In diesem Abschnitt geht es zunächst einmal darum, aktuelle Umgangs- und Nutzungsweisen mit kirchlichem Liedgut zu beleuchten. In erster Linie zielen die Fragen auf Mitarbeitende innerhalb der Kirche ab, die entsprechende Lieder nicht nur singen, sondern auch für verschiedenste weitere Aspekte verantwortlich zeichnen."
            }
        },
        {
            "name": "Fragen zur Liedauswahl",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "sind-sie-ggf-gelegentlich-verantwortlich-für-die-auswahl-von-liedern-für-kirchliche-anlässe",
                    "title": {
                        "de": "Sind sie (ggf. gelegentlich) verantwortlich für die Auswahl von Liedern für kirchliche Anlässe?"
                    },
                    "isRequired": true,
                    "choices": [
                        "Ja",
                        "Nein"
                    ]
                },
                {
                    "type": "panel",
                    "name": "panel1",
                    "elements": [
                        {
                            "type": "checkbox",
                            "name": "wie-suchen-sie-normalerweise-lieder-für-den-gottesdienst-aus",
                            "title": {
                                "de": "Wie suchen Sie normalerweise Lieder für den Gottesdienst aus?"
                            },
                            "isRequired": true,
                            "choices": [
                                "Nach Perikope / OGTL",
                                "Nach Anlass (z.B. Hochzeit, Taufe)",
                                "Nach Thema",
                                "Nach Bibelstelle",
                                "Nach Melodie"
                            ],
                            "showOtherItem": true
                        },
                        {
                            "type": "checkbox",
                            "name": "wie-bereiten-sie-die-liedauswahl-für-den-gottesdienst-vor",
                            "title": {
                                "de": "Wie bereiten Sie die Liedauswahl für den Gottesdienst vor?"
                            },
                            "isRequired": true,
                            "choices": [
                                "Notizen im Gesangbuch",
                                "Ausdruck von Liedblättern",
                                "Verwendung eines digitalen Dokuments"
                            ],
                            "showOtherItem": true
                        },
                        {
                            "type": "checkbox",
                            "name": "wie-teilen-sie-die-liedauswahl-mit-anderen-beteiligten-vor-dem-gottesdienst-",
                            "title": {
                                "de": "Wie teilen Sie die Liedauswahl mit anderen Beteiligten vor dem Gottesdienst? "
                            },
                            "isRequired": true,
                            "choices": [
                                "Mündlich / Telefonisch",
                                "Schriftlich (z.B. E-Mail, Brief)",
                                "In einem persönlichen Treffen"
                            ],
                            "showOtherItem": true
                        },
                        {
                            "type": "radiogroup",
                            "name": "wie-behalten-sie-den-überblick-über-lieder-die-in-ihrer-gemeinde-besonders-beliebt-sind-oder-die-sie-in-der-zukunft-verwenden-möchten",
                            "title": {
                                "de": "Wie behalten Sie den Überblick über Lieder, die in Ihrer Gemeinde besonders beliebt sind oder die Sie in der Zukunft verwenden möchten?"
                            },
                            "isRequired": true,
                            "choices": [
                                "Gar nicht",
                                "Notizen im Gesangbuch",
                                "Verwendung eines digitalen Dokuments",
                                "Aus dem Gedächtnis"
                            ],
                            "showOtherItem": true
                        }
                    ],
                    "visibleIf": "{sind-sie-ggf-gelegentlich-verantwortlich-für-die-auswahl-von-liedern-für-kirchliche-anlässe} = 'Ja'"
                }
            ],
            "title": {
                "de": "Fragen zur Liedauswahl"
            }
        },
        {
            "name": "Digitale Tools zum Umgang mit Kirchenliedern",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "nutzen-sie-bereits-digitale-tools-oder-plattformen-die-ihnen-die-nutzung-mit-kirchlichen-liedern-erleichert",
                    "title": {
                        "de": "Nutzen Sie bereits digitale Tools oder Plattformen, die Ihnen die Nutzung mit kirchlichen Liedern erleichert?"
                    },
                    "isRequired": true,
                    "choices": [
                        "Ja",
                        "Nein"
                    ]
                },
                {
                    "type": "panel",
                    "name": "panel2",
                    "elements": [
                        {
                            "type": "comment",
                            "name": "welche-digitalen-möglichkeiten-nutzen-sie",
                            "title": "Welche digitalen Möglichkeiten nutzen Sie?",
                            "isRequired": true
                        },
                        {
                            "type": "comment",
                            "name": "welche-funktionen-oder-möglichkeiten-sind-dabei-für-sie-essentiell-oder-helfen-ihnen-in-besonderer-weise-was-schätzen-sie-an-den-von-ihnen-genutzten-tools",
                            "title": "Welche Funktionen oder Möglichkeiten sind dabei für Sie essentiell oder helfen Ihnen in besonderer Weise? Was schätzen Sie an den von Ihnen genutzten Tools?"
                        },
                        {
                            "type": "comment",
                            "name": "gibt-es-funktionen-oder-möglichkeiten-die-sie-bei-den-von-ihnen-genutzten-tools-vermissen",
                            "title": "Gibt es Funktionen oder Möglichkeiten, die Sie bei den von Ihnen genutzten Tools vermissen?"
                        },
                        {
                            "type": "comment",
                            "name": "haben-sie-ggf-probleme-oder-schwierigkeiten-mit-den-von-ihnen-genutzten-tools-was-erschwert-ihnen-die-arbeit",
                            "title": "Haben Sie ggf. Probleme oder Schwierigkeiten mit den von Ihnen genutzten Tools? Was erschwert Ihnen die Arbeit?"
                        }
                    ],
                    "visibleIf": "{nutzen-sie-bereits-digitale-tools-oder-plattformen-die-ihnen-die-nutzung-mit-kirchlichen-liedern-erleichert} = 'Ja'"
                }
            ],
            "title": {
                "de": "Digitale Tools zum Umgang mit Kirchenliedern"
            }
        },
        {
            "name": "Digitale Möglichkeiten der neuen App",
            "elements": [
                {
                    "type": "html",
                    "name": "question2",
                    "html": {
                        "de": "Die folgenden Fragen zielen darauf ab, den Nutzwert bestimmter Funktionen und Möglichkeiten für Sie zu erfassen. Ein grundlegender Teil der Möglichkeiten ist bereits fest eingeplant, z.B.<br>\n- Allgemeine Noten- und Textdarstellung<br>\n- Strophenauswahl<br>\n- Verschlagwortung zur Suche nach Themen (z.B. \"Auferstehung\")<br>\n- Volltextsuche in den Liedtexten\n<br><br>\nEinige Funktionen sind jedoch aufwendiger in der Entwicklung, daher sind wir an den individuellen Bedürfnissen im Zusammenhang mit den Liedern interessiert.\n<br><br>\n(Rechtliche Aspekte der einzelnen Funktionen werden selbstverständlich im Vorfeld geklärt.)"
                    }
                },
                {
                    "type": "matrix",
                    "name": "geben-sie-an-wie-relevant-die-nachfolgenden-funktionen-für-sie-sind-bzw-sein-könnten-im-umgang-mit-kirchlichem-liedgut",
                    "title": "Geben Sie an, wie relevant die nachfolgenden Funktionen für Sie sind bzw. sein könnten im Umgang mit kirchlichem Liedgut.",
                    "isRequired": true,
                    "columns": [
                        "Unwichtig",
                        "Eher unwichtig",
                        "Eher wichtig",
                        "Sehr wichtig"
                    ],
                    "rows": [
                        "Anzeige von Liedtext + Akkordsymbole (\"Gitarren-Tab\")",
                        "Zwischentexte wie im EG (Bekenntnisse, Gebete, Abläufe etc.)",
                        "Hörbeispiele/Playbacks der Lieder",
                        "Kirchenjahresbezüge / Liturgischer Kalender",
                        "Rückwärtssuche nach Melodien (\"Lied vorsingen\")",
                        "Nutzung ohne Internetverbindung",
                        "Beamernutzung (Gottesdienst, Jugendarbeit etc.)",
                        "Gestaltung von druckbaren Liedblättern",
                        "Transponierbarkeit der Noten",
                        "Funktionen zum Teilen von Liedern und Liederlisten mit anderne NutzerInnen",
                        "Verknüpfung mit ergänzenden Materialien (z.B. Orgel- oder Chornoten, Liedkommentare etc.)",
                        "Anbindung an die Worship-Community",
                        "Auswertungsmöglichkeiten, z.B. \"Welche Lieder singt meine Gemeinde häufig?\""
                    ]
                },
                {
                    "type": "comment",
                    "name": "haben-sie-weitere-ideen-voraussetzungen-für-eine-liedplattform-die-wir-noch-nicht-bedacht-haben",
                    "title": "Haben Sie weitere Ideen / Voraussetzungen für eine Liedplattform, die wir noch nicht bedacht haben?"
                }
            ],
            "title": {
                "de": "Digitale Möglichkeiten der neuen App"
            }
        },
        {
            "name": "Demographische Fragen",
            "elements": [
                {
                    "type": "dropdown",
                    "name": "welcher-landeskirche-gehört-ihre-gemeinde-an",
                    "title": "Welcher Landeskirche gehört Ihre Gemeinde an?",
                    "isRequired": true,
                    "choices": [
                        "- Keine Angabe / unsicher",
                        "Anhalt",
                        "Baden",
                        "Bayern",
                        "Berlin-Brandenburg-schlesische Oberlausitz",
                        "Braunschweig",
                        "Bremen",
                        "Hannover",
                        "Hessen und Nassau",
                        "Kurhessen-Waldeck",
                        "Lippe",
                        "Mitteldeutschland",
                        "Norddeutschland (Nordkirche)",
                        "Oldenburg",
                        "Pfalz",
                        "Evangelisch-reformierte Kirche",
                        "Rheinland",
                        "Sachsen",
                        "Schaumburg-Lippe",
                        "Westfalen",
                        "Württemberg"
                    ]
                },
                {
                    "type": "text",
                    "name": "welche-funktion-bekleiden-sie-in-ihrer-gemeinde",
                    "title": "Welche \"Funktion\" bekleiden Sie in Ihrer Gemeinde?",
                    "description": {
                        "de": "z.B. PfarrerIn, OrganistIn, Gemeindemitglied etc."
                    }
                },
                {
                    "type": "radiogroup",
                    "name": "welchem-geschlecht-fühlen-sie-sich-zugehörig",
                    "title": "Welchem Geschlecht fühlen Sie sich zugehörig?",
                    "choices": [
                        "Männlich",
                        "Weiblich",
                        "Divers"
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "wie-alt-sind-sie",
                    "title": "Wie alt sind Sie?",
                    "choices": [
                        "Unter 25",
                        "25-29",
                        "30-39",
                        "40-49",
                        "50-59",
                        "60 oder älter"
                    ]
                },
                {
                    "type": "html",
                    "name": "question3",
                    "html": {
                        "de": "Falls Sie Interesse an der Entwicklung der Plattform haben, können Sie uns gerne Ihre Email-Adresse sowie Ihren Namen hinterlassen, sodass wir Sie über weitere Entwicklungen bzgl. des Projekts informieren können.<br><br>\n<i>Wir verwenden Ihre persönlichen Angaben ausschließlich für den genannten Informationszweck und geben sie nicht an Dritte weiter.</i>"
                    }
                },
                {
                    "type": "text",
                    "name": "wie-dürfen-wir-sie-ansprechen",
                    "title": {
                        "default": "Wie dürfen wir Sie ansprechen?",
                        "de": "Name"
                    }
                },
                {
                    "type": "text",
                    "name": "email-adresse",
                    "title": "Email-Adresse",
                    "validators": [
                        {
                            "type": "email",
                            "text": {
                                "de": "Keine gültige Email-Adresse"
                            }
                        }
                    ]
                },
                {
                    "type": "comment",
                    "name": "ebenso-können-sie-uns-gerne-an-dieser-stelle-einen-kommentar-hinterlassen-falls-ihnen-zum-projekt-möglicherweise-ganz-andere-ideen-gekommen-sind-wir-freuen-uns-über-jede-nachricht",
                    "title": "Ebenso können Sie uns gerne an dieser Stelle einen Kommentar hinterlassen, falls Ihnen zum Projekt möglicherweise ganz andere Ideen gekommen sind. Wir freuen uns über jede Nachricht!"
                }
            ],
            "title": {
                "de": "Demographische Fragen"
            },
            "description": {
                "de": "Im letzten Abschnitt bitten wir um ein paar persönliche Angaben, um Ihre Antworten besser in die Gesamtauswertung einordnen zu können. Die meisten Fragen sind optional, helfen uns jedoch sehr bei der Auswertung."
            }
        }
    ]
}
export default json;
